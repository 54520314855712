import React, { useState } from 'react';

import './Districts.css';

function Districts({ state, setState }) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredDistricts = state.menu.filter(district =>
    district.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDistrict = (district) => {
    setState(state => ({ ...state, selecteddistrict: district.id }));
    setState(state => ({ ...state, selectedcommunity: 0 }));
    let communities = district.communities;
    setState(state => ({ ...state, communities: communities }));
    setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null }));
  }

  return (
    <div className="districts">
      <input
        type="text"
        placeholder="Search districts..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="districts_search"
      />

      {
        filteredDistricts.map((district, index) => {
          const totalCommunities = district.communities.reduce((total, community) => total + community.no, 0);

          return (
            <div key={'district' + index}
              className={state.selecteddistrict == district.id ? 'districts_district districts_districtselected' : 'districts_district'}
              onClick={() => handleDistrict(district)}
            >
              {district.name} ({totalCommunities})
            </div>
          )
        })
      }
    </div>
  );
}

export default Districts;